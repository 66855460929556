import React from 'react';
import ReactECharts from 'echarts-for-react';

const AnalyticsChart = ({ config }) => {
  console.log('AnalyticsChart received config:', config); // Debug log

  if (!config) {
    return <div className="p-4 bg-gray-800/50 border border-gray-700 rounded-lg">
      <p className="text-gray-400">No chart data available</p>
    </div>;
  }

  const theme = {
    backgroundColor: 'transparent',
    textStyle: { color: '#fff' }
  };

  // Keep the original config structure
  const mergedOptions = {
    ...config,
    backgroundColor: theme.backgroundColor,
    textStyle: theme.textStyle,
    tooltip: {
      ...config.tooltip,
      textStyle: { color: '#000' } // Keep tooltip text readable
    }
  };

  console.log('Merged chart options:', mergedOptions); // Debug log

  return (
    <div className="p-4 bg-gray-800/50 border border-gray-700 rounded-lg">
      <ReactECharts
        option={mergedOptions}
        style={{ height: '400px', width: '100%' }}
        theme="dark"
        opts={{ renderer: 'canvas' }}
        onEvents={{
          'finished': () => console.log('Chart rendered successfully'),
          'error': (e) => console.error('Chart rendering error:', e)
        }}
      />
    </div>
  );
};

export default AnalyticsChart; 