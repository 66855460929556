import React, { useState, useEffect } from 'react';
import { Search, Command } from 'lucide-react';

const SearchDemo = () => {
  const [searchText, setSearchText] = useState('');
  const [responseText, setResponseText] = useState('');
  const [isTyping, setIsTyping] = useState(false);

  const demoQueries = [
    {
      query: "Show me all delayed shipments from last week",
      response: "Delayed Shipments Analysis:\n- Total Delayed: 12 shipments\n- Average Delay: 1.8 days\n- Main Reasons:\n  • Weather (45%)\n  • Capacity Issues (30%)\n  • Port Congestion (25%)\n- Most Affected Lanes: LAX→CHI, MIA→NYC"
    },
    {
      query: "What's our carrier compliance rate this quarter?",
      response: "Q3 2024 Carrier Metrics:\n- On-Time Pickup: 92.5%\n- On-Time Delivery: 94.8%\n- Documentation Accuracy: 98.2%\n- Insurance Compliance: 100%\n- Safety Rating Compliance: 96.5%\n- Top Performer: ABC Transport (99.1%)"
    },
    {
      query: "Show me top performing lanes by margin",
      response: "Top Lanes by Margin:\n1. CHI → NYC\n   - Margin: 32.5%\n   - Volume: 245 loads\n   - Avg Revenue: $3,850/load\n2. LAX → DEN\n   - Margin: 28.7%\n   - Volume: 178 loads\n   - Avg Revenue: $2,950/load"
    },
    {
      query: "What's our customer retention rate YTD?",
      response: "Customer Retention Analysis:\n- Overall Retention: 87.5%\n- Key Accounts: 94.2%\n- Top Reasons for Retention:\n  • Service Quality (65%)\n  • Competitive Rates (25%)\n  • Technology Platform (10%)\n- Average Customer Age: 3.2 years"
    },
    {
      query: "Generate Q3 operations efficiency report",
      response: "Q3 Operations Metrics:\n- Load Acceptance Rate: 92.5%\n- Spot vs Contract: 30/70\n- Empty Mile Rate: 12.5%\n- Detention Events: -15% MoM\n- Cross-Border Efficiency: +8%\n- Digital BOL Adoption: 78%"
    }
  ];

  const simulateTyping = async (text, delay = 25) => {
    setIsTyping(true);
    let currentText = '';
    for (let i = 0; i < text.length; i++) {
      currentText += text[i];
      setSearchText(currentText);
      await new Promise(resolve => setTimeout(resolve, delay));
    }
    setIsTyping(false);
  };

  const simulateResponse = async (response, delay = 8) => {
    setResponseText('');
    let currentText = '';
    for (let i = 0; i < response.length; i++) {
      currentText += response[i];
      setResponseText(currentText);
      await new Promise(resolve => setTimeout(resolve, delay));
    }
  };

  useEffect(() => {
    let isSubscribed = true;
    let currentIndex = 0;

    const runDemo = async () => {
      if (!isSubscribed) return;

      const demo = demoQueries[currentIndex];
      
      // Clear previous content
      setSearchText('');
      setResponseText('');
      
      // Type the query
      await simulateTyping(demo.query);
      if (!isSubscribed) return;
      
      // Wait briefly before showing response
      await new Promise(resolve => setTimeout(resolve, 300));
      if (!isSubscribed) return;
      
      // Show the response
      await simulateResponse(demo.response);
      if (!isSubscribed) return;
      
      // Wait before moving to next query
      await new Promise(resolve => setTimeout(resolve, 3000));
      
      // Move to next query or reset to beginning
      currentIndex = (currentIndex + 1) % demoQueries.length;
      
      // Run the demo again
      runDemo();
    };

    runDemo();

    return () => {
      isSubscribed = false;
    };
  }, []);

  return (
    <div className="w-full max-w-3xl mx-auto bg-[#1E2128] rounded-xl shadow-2xl overflow-hidden border border-gray-700">
      <div className="p-4">
        <div className="relative">
          <Search className="absolute left-3 top-3 text-gray-400" size={20} />
          <input
            type="text"
            value={searchText}
            readOnly
            className="w-full bg-[#2D3139] text-white pl-10 pr-4 py-2 rounded-lg border border-gray-700 focus:outline-none focus:ring-2 focus:ring-[#4D7EF9]"
            placeholder="Ask about shipments, tracking, or analysis..."
          />
          <Command className="absolute right-3 top-3 text-gray-400" size={20} />
        </div>
        <div className={`mt-4 text-gray-300 font-mono text-sm min-h-[200px] ${responseText ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`}>
          {responseText.split('\n').map((line, index) => (
            <div key={index} className="py-1">{line}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SearchDemo;