import React from 'react';
import AnalyticsChart from './Analytics/AnalyticsChart';

const AnalyticalResponse = ({ response }) => {
  if (!response?.data) {
    return <div className="text-red-400">Invalid response format</div>;
  }

  const { content, metrics, config } = response.data;

  return (
    <div className="space-y-4">
      {content?.title && (
        <h2 className="text-xl font-bold mb-4">{content.title}</h2>
      )}

      {content?.data && (
        <div className="space-y-2">
          {content.data.map((item, index) => (
            <div key={index} className="p-4 bg-gray-800/50 rounded-lg hover:bg-gray-800/70 transition-colors">
              <div className="font-bold text-lg">{item.label}</div>
              <div className="text-2xl text-blue-400 my-1">
                {typeof item.value === 'string' ? item.value : `${item.value}%`}
              </div>
              <div className="text-gray-400">{item.description}</div>
            </div>
          ))}
        </div>
      )}
      
      {metrics && metrics.length > 0 && config && (
        <div className="mt-4 p-4 bg-gray-800/50 rounded-lg">
          <AnalyticsChart config={config} />
        </div>
      )}
    </div>
  );
};

export default AnalyticalResponse;